import React from "react";
import {
  faFacebookF,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MailIcon from "@mui/icons-material/MailOutlineOutlined";
import CallIcon from "@mui/icons-material/PhoneOutlined";
import { Link } from "react-router-dom";
import "./TopBottomNavbar.css";
import { hoverScale } from "./StyleStandardize";
import { useTranslation } from "react-i18next";

const TopBottomNavbar = ({ inFooter }) => {
  const { t } = useTranslation();
  const contacts = [
    {
      icon: faFacebookF,
      link: "https://www.facebook.com/orientaltastebd?mibextid=LQQJ4d ",
    },
    {
      icon: faInstagram,
      link: "https://instagram.com/orientaltastebd_aunthentic?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr ",
    },
    {
      icon: faTiktok,
      link: "https://www.tiktok.com/@oriental_taste?_t=8hLli11Rzta&_r=1 ",
    },
  ];

  return (
    <div
      className={`px-2 py-[6px] z-40 ${
        inFooter === true ? "" : "fixed top-0"
      } h-max w-full flex md:flex-row flex-col justify-between items-center bg-black text-white`}
    >
      {!inFooter && (
        <div className="flex flex-row gap-5 text-xs md:text-sm">
          <Link
            to="tel:+8801799250004"
            className="flex flex-row gap-2 items-center custom-link-hover"
          >
            <CallIcon fontSize="small" />
            <span className="link-text">+88017-99250004</span>
          </Link>

          <Link
            className="flex flex-row gap-2 items-center custom-link-hover"
            to="mailto:orientaltastebd@gmail.com"
          >
            <MailIcon fontSize="small" />
            <span className="link-text">orientaltastebd@gmail.com</span>
          </Link>
        </div>
      )}

      <div className={`${inFooter ? "mx-auto" : ""} flex flex-row gap-5`}>
        {contacts.map((el, i) => (
          <a
            key={i}
            href={el.link}
            target="_blank"
            rel="noreferrer"
            className={`w-6 h-6 shadow-lg rounded-full flex items-center justify-center custom-link-hover ${
              i === 0
                ? "hover:bg-[#4267B2]"
                : i === 1
                ? "hover:bg-[#E1306C]"
                : "hover:bg-[#1a1a1a]"
            } transition ${hoverScale}`}
          >
            <FontAwesomeIcon icon={el.icon} />
          </a>
        ))}
      </div>

      {!inFooter && (
        <p className="text-xs md:text-sm animate-pulse">{t("opening_time")}</p>
      )}
    </div>
  );
};

export default TopBottomNavbar;
