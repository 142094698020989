import React from "react";
import { useTranslation } from "react-i18next";
import about_hero from "../../video/about_hero_5.mp4";
import MyLink from "../../componets/MyLink";
const HomeFirst = () => {
  const { t } = useTranslation();

  return (
    <div className="h-screen text-white">
      <video
        className="object-cover w-full h-full opacity-90 grayscale-[50%] md:grayscale-[30%] brightness-50"
        autoPlay
        loop
        muted
        preload="auto"
        src={about_hero}
      />
      <div className="absolute top-[28%] md:left-[7.5%] lg:left-[15%] flex flex-col gap-8 px-2 md:w-5/6 lg:w-2/3">
        <h1 className="text-3xl md:text-4xl font-Playfair font-bold md:leading-normal">
          <p>{t("home_first_tilte1")}</p>

          <p>{t("home_first_tilte2")}</p>
        </h1>

        <p className="text-lg lg:text-xl font-Lato">{t("home_first_text")}</p>

        <div className="flex gap-5 justify-center">
          <MyLink
            to="/Menu"
            text="order_food"
            textColor={"white"}
            bgColor={"[#E20613]"}
            font={"font-semibold"}
            big
          />

          <MyLink
            to="/Contact"
            text="reserve_table"
            textColor={"white"}
            bgColor={"transparent"}
            font={"font-semibold"}
            big
            borderWhite
            border2
          />
        </div>
      </div>
    </div>
  );
};

export default HomeFirst;
