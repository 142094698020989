import "./App.css";
import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home/Home";
import About from "./About/About";
import Menu from "./Menu/Menu";
import Contact from "./Contact/Contact";
import Navbar from "./componets/Navbar";
import FloatingButton from "./componets/FloatingButton";
import Footer from "./componets/Footer";
import Gallery from "./Gallery/Gallery";
import Order from "./Menu/components/Order";
import OrdersByDate from "./Admin/Dashboard/FoodTableGet";
import Member from "./Member/Member";

function App() {
  const { pathname } = useLocation();
  const [scrolledPastFirstScreen, setScrolledPastFirstScreen] = useState(false);
  const position = scrolledPastFirstScreen;
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > window.innerHeight;
      setScrolledPastFirstScreen(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return (
    <div className="App">
      <Navbar />
      {position === true && (
        <div className="fixed bottom-0 right-0 p-2">
          <FloatingButton onClick={() => {}} />
        </div>
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Menu" element={<Menu />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/order" element={<Order />} />
        <Route path="/dashboard" element={<OrdersByDate />} />
        <Route path="/BecomeMember" element={<Member />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
