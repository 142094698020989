import React from "react";
import { Link } from "react-router-dom";
import location from "../img/seo_optimized/map_oriental_taste.jpg";
import DirectionIcon from "@mui/icons-material/ForkRightTwoTone";
import { useTranslation } from "react-i18next";
import "./TopBottomNavbar.css";
const Map = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-1">
      <Link
        to="https://www.google.com/maps/place/Oriental+Taste/@23.7903022,90.4101007,21z/data=!4m14!1m7!3m6!1s0x3755c70a4fcc9fc3:0x90630f5690d341f4!2sA.P.+Moller+Maersk+Line!8m2!3d23.7903516!4d90.4102355!16s%2Fg%2F1vqtf4p_!3m5!1s0x3755c77b698e38cf:0xaa0c057e953816e5!8m2!3d23.7903354!4d90.4101902!16s%2Fg%2F11krrb6x7j?entry=ttu"
        target="_blank"
      >
        <img
          src={location}
          alt={t("location")}
          className="h-72 lg:h-80 rounded-3xl shadow-lg"
        />
      </Link>
      <Link
        to="https://www.google.com/maps/dir//Oriental+Taste,+Plot%23+76%2FA+(5th+Floor),+Block%23+M,+Road%23+11,+new+Bridge,+Dhaka+1213/@23.7903022,90.4101007,21z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3755c77b698e38cf:0xaa0c057e953816e5!2m2!1d90.4101902!2d23.7903354?entry=ttu"
        target="_blank"
        className="md:ml-auto flex items-center md:justify-end custom-link-hover"
      >
        <DirectionIcon />{" "}
        <span className="text-sm lg:text-base link-text">
          {t("see_direction")}
        </span>
      </Link>
    </div>
  );
};

export default Map;
