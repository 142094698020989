import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import eng from "../img/country_flags/eng.webp";
import jp from "../img/country_flags/jp.jpg";
import kr from "../img/country_flags/kr.png";
import thai from "../img/country_flags/thai.png";
import ch from "../img/country_flags/ch.png";
import bd from "../img/country_flags/bd.png";
import france from "../img/country_flags/france.png";

const languageOptions = [
  { value: "english", label: "English", image: eng, alt: "USA" },
  { value: "chinese", label: "Chinese", image: ch, alt: "China" },
  { value: "japanese", label: "Japanese", image: jp, alt: "Japan" },
  { value: "korean", label: "Korean", image: kr, alt: "Korea" },
  { value: "thai", label: "Thai", image: thai, alt: "Thailand" },
  { value: "french", label: "French", image: france, alt: "France" },
  { value: "bangla", label: "Bengali", image: bd, alt: "Bangladesh" },
];
const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  const handleLanguageChange = (newLang) => {
    i18n.changeLanguage(newLang);
    setShowOptions(false);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative inline-block text-white font-Lato"
      ref={dropdownRef}
    >
      <button
        onClick={toggleOptions}
        className="flex items-center space-x-2 bg-[#040C0C] rounded-xl px-2 py-1 focus:border-[#E20613]"
      >
        <img
          src={
            languageOptions.find((opt) => opt.value === i18n.language)?.image
          }
          alt={languageOptions.find((opt) => opt.value === i18n.language)?.alt}
          className="w-6 h-5"
        />
        <span>
          {languageOptions.find((opt) => opt.value === i18n.language)?.label}
        </span>
      </button>
      <ul
        className={`absolute z-10 top-full right-0 w-max bg-[#040C0C] border border-white rounded-xl mt-1 py-1 ${
          showOptions ? "opacity-100 scale-y-100" : "opacity-0 scale-y-0"
        } transition-all duration-300 transform origin-top-right`}
      >
        {languageOptions.map((option) => (
          <li
            key={option.value}
            onClick={() => handleLanguageChange(option.value)}
            className="flex items-center cursor-pointer px-2 py-1"
          >
            <img src={option.image} alt={option.alt} className="w-6 h-5 mr-2" />
            <span>{option.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSwitcher;
