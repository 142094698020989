import React from "react";
import home51 from "../../img/seo_optimized/vip_rooms.png";
import HomeSixth from "./HomeSixth";
import { useTranslation } from "react-i18next";
import MyLink from "../../componets/MyLink";
import { Fade, Slide } from "react-reveal";
const HomeFifth = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#1D1D1B] flex flex-col gap-10 lg:gap-5">
      <div className="lg:h-screen flex flex-col lg:flex-row gap-5 items-center justify-evenly m-auto py-10 w-11/12">
        <Fade bottom duration={1400}>
          <img
            src={home51}
            alt={t("vip_rooms")}
            className="w-11/12 md:w-3/4 lg:w-[45%]"
          />
        </Fade>
        <Slide bottom duration={1200}>
          <div className="text-white text-left flex flex-col gap-5 justify-center px-4">
            <h2 className="text-4xl md:text-5xl font-Playfair">
              {t("home_fifth_title_11")} <br />
              {t("home_fifth_title_12")}
            </h2>

            <h2 className="text-3xl md:text-4xl font-Playfair">
              商务会议室和美味佳肴
            </h2>
            <p className="text-lg md:w-96 font-Lato">{t("home_fifth_text")}</p>
            <MyLink
              to="/Contact"
              text="reserve_table"
              textColor={"white"}
              bgColor={"tranparent"}
              borderRed
              textSize={"sm"}
            />
          </div>
        </Slide>
      </div>

      <HomeSixth />
    </div>
  );
};

export default HomeFifth;
