import React from "react";
import FoodItems from "./components/FoodItems";
import MenuFirst from "./components/MenuFirst";
import Helmet from "react-helmet";

const Menu = () => {
  return (
    <>
      <Helmet>
        <link ref="canonical" href="https://www.orientaltastebd.com/Menu" />
      </Helmet>
      <div className="bg-[#040C0C]">
        <MenuFirst />
        <FoodItems />
      </div>
    </>
  );
};

export default Menu;
