import React, { useState, useEffect } from "react";
import HamburgerButton from "./Hamburger";
import NavbarLogo from "./NavbarLogo";
import { useLocation, Link } from "react-router-dom";
import LanguagteSwitcher from "./LanguagteSwitcher";
import TopBottomNavbar from "./TopBottomNavbar";
import { useTranslation } from "react-i18next";
import { hoverScale } from "./StyleStandardize";

const Navbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [scrolledPastFirstScreen, setScrolledPastFirstScreen] = useState(false);
  const position = scrolledPastFirstScreen;

  const navbar = [
    {
      name: t("home"),
      path: "/",
    },
    {
      name: t("about"),
      path: "/About",
    },
    {
      name: t("menu"),
      path: "/Menu",
    },
    {
      name: t("gallery"),
      path: "/Gallery",
    },
    {
      name: t("contact"),
      path: "/Contact",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > window.innerHeight;
      setScrolledPastFirstScreen(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className="top-0 font-Lato"
      role="navigation"
      aria-label="main navigation"
    >
      {position === false && <TopBottomNavbar />}

      <div className="container">
        <div
          className={`z-40 fixed ${
            position === false
              ? "top-12 md:top-8 transition-top duration-700 ease-in-out"
              : "top-0 transition-top duration-700 ease-in-out"
          } left-0 right-0 w-full
        lg:w-max bg-opacity-50 backdrop-blur-lg lg:backdrop-blur-0 h-14
        flex lg:flex-row justify-between px-2 lg:pl-12 lg:max-w-7xl items-center shadow-md lg:shadow-none`}
        >
          <div onClick={() => setActive(false)}>
            <NavbarLogo link />
          </div>

          <div className="flex gap-3 justify-center items-center lg:hidden p-1 rounded-md">
            <LanguagteSwitcher />

            <HamburgerButton
              active={active}
              onClick={() => setActive(!active)}
            />
          </div>
        </div>

        <ul
          className={`z-30 fixed ${
            position === false
              ? "top-8 transition-top duration-700 ease-in-out"
              : "top-0 transition-top duration-700 ease-in-out"
          } lg:right-0 w-full lg:bg-opacity-50 backdrop-blur ${
            active ? "translate-x-0 text-white text-2xl" : "-translate-x-full"
          } lg:translate-x-0 h-[calc(100vh_-_3rem)] lg:h-14 mt-12 flex flex-col justify-center lg:flex-row gap-6 lg:gap-3 text-2xl lg:text-lg text-center items-center lg:mt-0 font-medium`}
        >
          {navbar.map((el) => (
            <li
              key={el.path}
              className={
                (location.pathname === el.path
                  ? "text-[#E20613] border-b-2 border-[#E20613] py-[2px] font-semibold"
                  : "text-white") +
                " hover:text-[#E20613] hover:scale-110 transition-transform duration-300 cursor-pointer"
              }
            >
              <Link to={el.path} onClick={() => setActive(false)}>
                <p
                  className={`px-2 ${
                    location.pathname === el.path
                      ? "text-[#E20613] border-b-2 border-[#E20613]"
                      : ""
                  }`}
                >
                  {el.name}
                </p>
              </Link>
            </li>
          ))}

          <div className="lg:hidden block">
            <Link
              to="/BecomeMember"
              className={`${hoverScale} text-white text-lg z-30  px-3 py-2 rounded-full border-2 border-white cursor-pointer bg-black`}
            >
              {t("become_member")}
            </Link>
          </div>
        </ul>

        {/* For large screen */}
        <div
          className={`text-white z-30 hidden lg:flex flex-row gap-2 items-center fixed ${
            position === false
              ? "top-10 transition-top duration-700 ease-in-out"
              : "top-3 transition-top duration-700 ease-in-out"
          } right-1 `}
        >
          <Link
            to="/BecomeMember"
            className={`${hoverScale} px-3 py-2 rounded-full border-2 border-white cursor-pointer bg-black`}
          >
            {t("become_member")}
          </Link>
          <LanguagteSwitcher />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
