import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import home2 from "../../img/seo_optimized/steemed_beef_brisket.jpg";
import home3 from "../../img/seo_optimized/Braised_lamb_in_yellow_sauce.jpg";
import home4 from "../../img/seo_optimized/Oriental_Taste_Restaurant_about.jpg";
import home5 from "../../img/seo_optimized/Beef_Zhajiang_Noodles_about.jpg";

const AboutFirst = () => {
  const { t } = useTranslation();
  const [textIndex, setTextIndex] = useState(0);
  const texts = [
    t("the_experience"),
    t("our_cuisin"),
    t("healty_food_for_family"),
    t("culinary_oasis"),
  ];

  const textTexts = [
    t("the_experience_text"),
    t("our_cuisin_text"),
    t("healty_food_for_family_text"),
    t("culinary_oasis_text"),
  ];

  const textVariants = {
    hidden: { opacity: 0, y: "-100%" },
    visible: { opacity: 1, y: "0%" },
    exit: { opacity: 0, y: "100%" },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [home2, home3, home4, home5];
  const backgroundStyle = {
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image 1s ease-in-out",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 8000);

    return () => clearInterval(interval);
  }, [texts.length, images.length]);

  return (
    <div className="h-screen text-white" style={backgroundStyle}>
      {/*  */}
      <div className="px-2 flex flex-col gap-6 text-white font-Lato md:w-2/3 lg:w-1/2 h-screen m-auto justify-center">
        <motion.div
          key={textIndex}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={textVariants}
          transition={{ duration: 1 }}
          className="flex flex-col gap-8"
        >
          <h1 className="font-Playfair text-3xl md:text-4xl font-bold md:leading-normal animate-fadeInOut transition delay-150 duration-300 ease-in-out">
            {texts[textIndex]}
          </h1>
          <p className="text-lg lg:text-xl font-Lato">{textTexts[textIndex]}</p>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutFirst;
