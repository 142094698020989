import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import mail_icon from "../../img/mail_icon.png";
import phone_icon from "../../img/phone_icon.png";
import location_icon from "../../img/location_icon.png";
import { generalInput } from "../../componets/StyleStandardize";
import { toast } from "react-toastify";
import weChatQR from "../../img/weChatQRCode.jpeg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
const ContactSecond = () => {
  const [customerName, setCustomerName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [designation, setDesignation] = useState("");
  const [nationality, setNationality] = useState("");
  const [people, setPeople] = useState(0);
  const { t } = useTranslation();

  const locationDetails = [
    {
      title: t("visit_us"),
      to: "https://www.google.com/maps/place/Oriental+Taste/@23.7903022,90.4101007,21z/data=!4m14!1m7!3m6!1s0x3755c70a4fcc9fc3:0x90630f5690d341f4!2sA.P.+Moller+Maersk+Line!8m2!3d23.7903516!4d90.4102355!16s%2Fg%2F1vqtf4p_!3m5!1s0x3755c77b698e38cf:0xaa0c057e953816e5!8m2!3d23.7903354!4d90.4101902!16s%2Fg%2F11krrb6x7j?entry=ttu",
      icon: location_icon,
      text: t("oriental_taste_location"),
      alt: t("location"),
    },
    {
      title: t("call_us"),
      to: "tel:+8801799250004",
      icon: phone_icon,
      text: "+88017-99250004",
      alt: t("phone"),
    },
    {
      title: t("email_us"),
      to: "mailto:orientaltastebd@gmail.com",
      icon: mail_icon,
      text: "orientaltastebd@gmail.com",
      alt: t("mail"),
    },
  ];

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = {
        customerName,
        contact,
        email,
        date,
        time,
        people,
        designation,
        nationality,
      };
      const response = await fetch(
        "https://server.orientaltastebd.com/home/bookings",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log("Booking created successfully");
      toast.success(`${t("table_reservation_request")}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        style: {
          backgroundColor: "black",
        },
      });
    } catch (error) {
      console.error("Error creating booking:", error.message);
    }
  };
  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="flex flex-row flex-wrap lg:flex-nowrap gap-10 text-white py-10 px-2 w-5/6 mx-auto justify-around">
      <div className="flex flex-col gap-6  lg:w-[40%]">
        <p className="text-3xl md:text-4xl font-Playfair font-semibold text-left text-[#E20613] mb-3">
          {t("get_in_touch")}
        </p>

        <p className="text-lg text-left font-Lato">{t("get_in_touch_text")}</p>

        <div className="text-left flex flex-col gap-6">
          {locationDetails.map((el, i) => {
            return (
              <div key={i} className="flex flex-col gap-2">
                <p className="text-xl text-[#E20613] font-medium">{el.title}</p>
                <Link
                  to={el.to}
                  target="_blank"
                  className={`flex flex-row gap-2 custom-link-hover ${
                    i === 0 ? "w-72" : "w-max"
                  }`}
                >
                  <img src={el.icon} alt={el.alt} className={`w-6 h-6`} />
                  <p className="text-lg link-text text-white">{el.text}</p>
                </Link>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col gap-2 text-left custom-link-hover">
          <p className="text-xl text-[#E20613] font-medium">
            {t("scan_wechat")}
          </p>
          <img
            src={weChatQR}
            alt={t("weChatQR")}
            className={
              "w-max h-max cursor-pointer hover:scale-105 transition-transform duration-300"
            }
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <p className="text-3xl md:text-4xl font-Playfair font-semibold text-left text-[#E20613] mb-3">
          {t("request_to_reserve_table")}
        </p>
        <div className="bg-[#1D1D1B] p-6 w-max">
          <form
            className="flex flex-col md:grid md:grid-cols-2 gap-6 font-Lato text-left text-[#E20613]"
            onSubmit={handleFormSubmit}
          >
            {/* Form field needs to be added here */}
            <label className="flex flex-col gap-2">
              {t("your_name")}
              <input
                type="text"
                value={customerName}
                onChange={(e) => {
                  setCustomerName(e.target.value);
                }}
                className={`${generalInput}`}
              />
            </label>

            <label className="flex flex-col gap-2">
              {t("designation")}
              <input
                type="text"
                name="designation"
                value={designation}
                onChange={(e) => {
                  setDesignation(e.target.value);
                }}
                className={`${generalInput}`}
              />
            </label>

            <label className="flex flex-col gap-2">
              {t("date")}
              <input
                type="date"
                name="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                className={`${generalInput}`}
                min={today} // Set the minimum date to today
              />
            </label>
            <label className="flex flex-col gap-2">
              {t("time")}
              <DatePicker
                selected={time}
                onChange={(date) => setTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className={`${generalInput}`}
              />
            </label>

            <label className="flex flex-col gap-2">
              {t("contact")}
              <input
                type="text"
                name="contact"
                value={contact}
                onChange={(e) => {
                  setContact(e.target.value);
                }}
                className={`${generalInput}`}
              />
            </label>
            <label className="flex flex-col gap-2">
              {t("email")}
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className={`${generalInput}`}
              />
            </label>

            <label className="flex flex-col gap-2">
              {t("no_of_people")}
              <input
                type="number"
                name="people"
                value={people}
                onChange={(e) => {
                  setPeople(Math.max(1, e.target.value));
                }}
                className={`${generalInput}`}
                min={1}
              />
            </label>

            <label className="flex flex-col gap-2">
              {t("nationality")}
              <input
                type="text"
                name="nationality"
                value={nationality}
                onChange={(e) => {
                  setNationality(e.target.value);
                }}
                className={`${generalInput}`}
              />
            </label>

            <button
              className="m-auto w-max p-3 font-Lato col-span-2 rounded-full cursor-pointer font-semibold bg-[#E20613] text-white"
              type="submit"
            >
              {t("reserve_table")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSecond;
