import React from "react";
import GalleryFirst from "./sections/GalleryFirst";
import GallerySecond from "./sections/GallerySecond";
import Helmet from "react-helmet";

const Gallery = () => {
  return (
    <>
      <Helmet>
        <link ref="canonical" href="https://www.orientaltastebd.com/Gallery" />
      </Helmet>
      <div className="bg-[#040C0C]">
        <GalleryFirst />
        <GallerySecond />
      </div>
    </>
  );
};

export default Gallery;
