import React from "react";
import gallery1 from "../img/seo_optimized/Oriental_Taste_Gallery_Hero.jpg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
const Member = () => {
  const { t } = useTranslation();
  const backgroundStyle = {
    backgroundImage: `url(${gallery1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image 1s ease-in-out",
  };

  return (
    <div className="h-screen text-white" style={backgroundStyle}>
      <div className="h-screen flex px-2 md:w-2/3 lg:w-1/2 m-auto justify-center items-center">
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 1 }}
          className="flex flex-col gap-8"
        >
          <h1 className="text-3xl md:text-5xl font-Playfair font-bold md:leading-normal">
            {t("become_member_title")}
          </h1>
        </motion.div>
      </div>
    </div>
  );
};

export default Member;
