import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";
import {
  generalInput,
  hoverScale,
  redButton,
} from "../../componets/StyleStandardize";
import RemoveFromCart from "@mui/icons-material/HighlightOff";

const Order = ({ orderItems, handleDeleteItem }) => {
  const { t } = useTranslation();
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [selectDeliveryType, setSelectDeliveryType] = useState("");
  const deliveryType = ["Home Delivery", "Pick-up Service"];
  const [Address, setAddress] = useState("");
  const [successfulOrder, setSuccessfulOrder] = useState(false);
  const [flip, setFlip] = useState(false);

  const handleSelect = (e) => {
    const selected = e.target.value;
    setSelectDeliveryType(selected);
  };

  const [quantities, setQuantities] = useState([]);

  useEffect(() => {
    // Set the initial quantities to 1 when orderItems change
    setQuantities(orderItems.map(() => 1));
  }, [orderItems]);
  const handleQuantityChange = (index, value) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = value;
    setQuantities(updatedQuantities);
  };

  const handleSubmit = async () => {
    const orderItemsWithQuantities = orderItems.map((item, idx) => ({
      item: item.item,
      quantity: quantities[idx],
      cost: item.totalCost * quantities[idx],
    }));

    const totalCost = orderItems.reduce(
      (acc, item, idx) => acc + quantities[idx] * item.cost,
      0
    );

    const updatedItem = JSON.stringify(orderItemsWithQuantities);
    try {
      const order = {
        customerName,
        email,
        contact,
        deliveryType: selectDeliveryType,
        orderItemsJSON: updatedItem,
        Address,
        totalCost: totalCost,
      };
      await axios.post(
        "https://server.orientaltastebd.com/menu/createOrder",
        order
      );
      setSuccessfulOrder(true);
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0.5 },
    reset: true,
    reverse: flip,
    delay: 800,
    onRest: () => setFlip(!flip),
  });

  const isAnyFieldEmpty =
    !customerName ||
    !email ||
    !contact ||
    !selectDeliveryType ||
    (selectDeliveryType === "Home Delivery" && !Address) ||
    orderItems.some((item, idx) => quantities[idx] <= 0);
  return (
    <>
      {successfulOrder ? (
        <div className="text-white text-center font-Playfair text-[28px]">
          <p>{t("order_place_text_title")}</p>
          <p className="animate-pulse">{t("order_place_text")}</p>
        </div>
      ) : (
        <div className="flex flex-col gap-3 items-center font-Lato bg-[#1D1D1B] text-white rounded-3xl py-5">
          <div>
            <label className="label ">{t("your_name")}</label>
            <input
              type="text"
              placeholder={t("your_name")}
              className={`${generalInput}`}
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              id="customerName"
              required
            />
          </div>

          <div>
            <label className="label ">{t("email")}</label>
            <input
              type="email"
              placeholder={t("email")}
              className={`${generalInput}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              required
            />
          </div>

          <div>
            <label className="label">{t("contact")}</label>
            <input
              type="text"
              placeholder={t("contact")}
              className={`${generalInput}`}
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              id="Number"
              required
            />
          </div>

          <div>
            <label className="label">{t("delivery_type")}</label>
            <select
              className={`${generalInput}`}
              onChange={handleSelect}
              value={selectDeliveryType}
            >
              <option value="" disabled>
                {t("select_type")}
              </option>
              {deliveryType.map((month, index) => (
                <option
                  className="text-slate-600 font-Lato w-32"
                  key={index}
                  value={month}
                >
                  {month}
                </option>
              ))}
            </select>
          </div>

          {selectDeliveryType === "Home Delivery" && (
            <div>
              <label className="label">{t("address")}</label>
              <input
                type="text"
                placeholder="Enter Address"
                className={`${generalInput}`}
                value={Address}
                onChange={(e) => setAddress(e.target.value)}
                id="Address"
                required
              />
            </div>
          )}

          {orderItems.map((item, idx) => (
            <div
              className="card flex flex-col gap-3 shadow-lg bg-black w-60 md:w-64 lg:w-72 p-2"
              key={idx}
            >
              <div className="flex flex-col gap-1">
                <button
                  className={`ml-auto ${hoverScale}`}
                  onClick={() => handleDeleteItem(idx)}
                >
                  <RemoveFromCart fontSize="1px" />
                </button>
                <p className="font-Lato font-semibold text-[#E20613] grid grid-cols-3 gap-2">
                  <p className="col-span-2 text-left">{item.item}</p>
                  <p>{item.cost}</p>
                  <input
                    type="number"
                    className={`col-span-2 input input-bordered input-sm w-20 bg-[#040C0C] focus:border-[#E20613] text-white`}
                    value={quantities[idx]}
                    onChange={(e) => handleQuantityChange(idx, e.target.value)}
                    min={1}
                  />
                  {quantities[idx] > 0 ? (
                    <p>{quantities[idx] * item.totalCost}</p>
                  ) : (
                    <p>{item.totalCost}</p>
                  )}
                </p>
              </div>
            </div>
          ))}

          {orderItems.length > 0 && (
            <>
              <div className="text-[#E20613] font-semibold">
                Total Cost of All Items:{" "}
                {orderItems.reduce(
                  (acc, item, idx) => acc + quantities[idx] * item.cost,
                  0
                )}{" "}
                Tk.
              </div>
              {}
              <button
                className={`${redButton}`}
                onClick={handleSubmit}
                disabled={isAnyFieldEmpty}
              >
                {t("create_order")}
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Order;
