import React from "react";
import HomeFirst from "./sections/HomeFirst";
import HomeSecond from "./sections/HomeSecond";
import HomeThird from "./sections/HomeThird";
import HomeFourth from "./sections/HomeFourth";
import HomeFifth from "./sections/HomeFifth";
import HomeSeventh from "./sections/HomeSeventh";
import Helmet from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Oriental Taste | Chinese Cuisine & Dining Restaurant | Oriental
        </title>
        <meta
          name="description"
          content="Embark on a culinary journey at an oriental taste restaurant, where the best authentic Chinese cuisine meets contemporary fine dining in a stunning ambiance."
        />
        <link ref="canonical" href="https://www.orientaltastebd.com/" />
      </Helmet>
      <div className="bg-[#040C0C]">
        <HomeFirst />
        <div className="flex flex-col gap-5">
          <HomeSecond />
          <HomeThird />
          <HomeFourth />
          <HomeFifth />
          <HomeSeventh />
        </div>
      </div>
    </>
  );
};

export default Home;
