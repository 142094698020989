import React, { useEffect, useRef, useState } from "react";
import home2 from "../../img/home2.png";
import home21 from "../../img/seo_optimized/signature_peking_duck.png";
import home22 from "../../img/seo_optimized/signature_steemed_beef_brisket.png";
import star from "../../img/christmas_star.png";
import steemed_beef_brisket from "../../img/signature/steemed_beef_brisket.jpg";
import five_spice_chicken from "../../img/signature/five_spice_chicken.jpg";
import pecking_duck from "../../img/signature/signature_peking_duck.jpg";
import Medicinal_Pigeon_Soup from "../../img/signature/medicinal_pigeon_soup.jpg";
// import Mango_Signature from "../../img/signature/mango_pomelo _sago.JPG";
import dunhuang_lou_grilled_fish from "../../img/signature/dunhuang_lou_grilled_fish.jpg";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation, AnimatePresence } from "framer-motion";

const HomeSecond = () => {
  const { t } = useTranslation();
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });
  const [showModal, setShowModal] = useState(false);
  const [selectedFood, setSelectedFood] = useState(null);
  const modalRef = useRef();

  const openModal = (food) => {
    setSelectedFood(food);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, x: 0 });
    } else {
      controls.start({ opacity: 0, x: 100 });
    }
  }, [controls, inView]);

  const backgroundStyle = {
    backgroundImage: `url(${home2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const points = [
    {
      text: t("peking_duck"),
      description: t("Peking_Duck_desc"),
      src: pecking_duck,
      alt: t("signature"),
    },
    {
      text: t("five_spice_chicken"),
      description: t("Five_spice_chicken_desc"),
      src: five_spice_chicken,
      alt: t("five_spice_chicken"),
    },
    {
      text: t("steamed_beef"),
      description: t("Steamed_beef_desc"),
      src: steemed_beef_brisket,
      alt: t("steemed_beef_brisket"),
    },
    {
      text: t("dunhuang_lou_grilled_fish"),
      description: t("Dunhuang_lou_grilled_fish_desc"),
      src: dunhuang_lou_grilled_fish,
      alt: t("dunhuang_lou_grilled_fish"),
    },
    {
      text: t("medicinal_pigeon_soup"),
      description: t("Medicinal_Pigeon_Soup_desc"),
      src: Medicinal_Pigeon_Soup,
      alt: t("Medicinal_Pigeon_Soup"),
    },
  ];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showModal]);

  return (
    <>
      <div className="md:h-screen" style={backgroundStyle}>
        <div
          className="md:h-screen flex flex-col-reverse lg:flex-row gap-6 lg:gap-20 items-center justify-center py-10 m-auto w-11/12"
          ref={ref}
        >
          <motion.div
            className="text-white text-left flex flex-col gap-5"
            initial={{ opacity: 0, x: -100 }}
            animate={controls}
            transition={{ duration: 2 }}
          >
            <h2 className="text-4xl md:text-5xl font-Playfair">
              {t("home_second_title")}
            </h2>
            <h2 className="text-3xl md:text-4xl font-Playfair">招牌菜</h2>
            {points.map((el, i) => (
              <motion.div
                key={i}
                className="flex gap-2 cursor-pointer custom-link-hover md:w-max"
                initial={{ opacity: 0, x: -100 }}
                animate={controls}
                transition={{ duration: 2, delay: i * 0.3 }}
                onClick={() => openModal(el)}
              >
                <img src={star} alt={t("star")} className="h-max my-auto" />
                <p className="text-lg font-Lato link-text">
                  {el.text}
                </p>
              </motion.div>
            ))}
          </motion.div>

          <motion.div
            className="flex flex-col-reverse gap-6"
            initial={{ opacity: 0, x: 100 }}
            animate={controls}
            transition={{ duration: 1, delay: 1.2 }}
          >
            <motion.img
              src={home22}
              alt={t("signature_peking_duck")}
              className="w-68 h-48 mr-32 z-0"
              initial={{ opacity: 0, x: 100 }}
              animate={controls}
              transition={{ duration: 1, delay: 1 }}
            />
            <motion.img
              src={home21}
              alt={t("signature_steemed_beef_brisket")}
              className="w-68 h-48 ml-auto -mb-10 z-10"
              initial={{ opacity: 0, x: 100 }}
              animate={controls}
              transition={{ duration: 1, delay: 0.2 }}
            />
          </motion.div>
        </div>
      </div>

      <AnimatePresence>
        {showModal && selectedFood && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="text-left w-4/5 md:w-1/2 bg-[#1D1D1B] text-white font-Lato p-4 md:p-8 rounded-lg flex flex-col lg:flex-row gap-6"
              ref={modalRef}
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.5, opacity: 0 }}
            >
              <div>
                <h2 className="font-Playfair text-2xl font-bold mb-4">
                  {selectedFood.text}
                </h2>
                <p className="">{selectedFood.description}</p>
              </div>
              <img
                src={selectedFood.src}
                alt={selectedFood.alt}
                className="w-56 h-48 m-auto"
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default HomeSecond;
