import React from "react";
import location_icon from "../img/location_icon.png";
import mail_icon from "../img/mail_icon.png";
import phone_icon from "../img/phone_icon.png";
import weChat_icon from "../img/weChatIconRed.png";
import { Link } from "react-router-dom";
import TopBottomNavbar from "./TopBottomNavbar";
import Map from "./Map";
import { useTranslation } from "react-i18next";
import MyLink from "./MyLink";
import "./TopBottomNavbar.css";
import CopyToClipboard from "./CopyToClipboard";

const Footer = () => {
  const { t } = useTranslation();

  const locationDetails = [
    {
      title: t("visit_us"),
      to: "https://www.google.com/maps/place/Oriental+Taste/@23.7903022,90.4101007,21z/data=!4m14!1m7!3m6!1s0x3755c70a4fcc9fc3:0x90630f5690d341f4!2sA.P.+Moller+Maersk+Line!8m2!3d23.7903516!4d90.4102355!16s%2Fg%2F1vqtf4p_!3m5!1s0x3755c77b698e38cf:0xaa0c057e953816e5!8m2!3d23.7903354!4d90.4101902!16s%2Fg%2F11krrb6x7j?entry=ttu",
      icon: location_icon,
      text: t("oriental_taste_location"),
      alt: t("location"),
    },
    {
      title: t("call_us"),
      to: "tel:+8801799250004",
      icon: phone_icon,
      text: "+88017-99250004",
      alt: t("phone"),
    },
    {
      title: t("email_us"),
      to: "mailto:orientaltastebd@gmail.com",
      icon: mail_icon,
      text: "orientaltastebd@gmail.com",
      alt: t("mail"),
    },
  ];
  return (
    <>
      <div className="bg-[#1D1D1B] text-white">
        <div className="flex flex-col gap-5">
          <div className="text-left flex flex-row gap-5 mx-auto justify-between lg:w-3/4 py-10 px-4 font-Lato">
            {/* Contact Details */}
            <div className="flex flex-col gap-10">
              <h1 className="text-4xl md:text-5xl italic font-Playfair">
                {t("how_to_find_us")}
              </h1>

              <p className="font-Lato w-80 md:w-96">{t("footer_text")}</p>

              {/* Map generic component for small to medium screen*/}
              <div className="lg:hidden">
                <Map />
              </div>

              <h2 className="text-md animate-pulse">{t("opening_time")}</h2>

              {/* Buttons Delivery and Pick Up */}
              <div className="flex gap-5 justify-center lg:justify-start">
                <MyLink
                  to="/Menu"
                  text="order_food"
                  textColor={"white"}
                  bgColor={"[#E20613]"}
                  font={"font-semibold"}
                />

                <MyLink
                  to="/Contact"
                  text="reserve_table"
                  textColor={"white"}
                  bgColor={"[#E20613]"}
                  font={"font-semibold"}
                />
              </div>

              <Link
                to="/"
                target="_blank"
                className={`flex flex-row gap-2 custom-link-hover w-max`}
              >
                <h3 className={`italic cursor-pointer link-text`}>
                  {t("blog")}
                </h3>
              </Link>
            </div>

            {/* Map generic component for large screen*/}
            <div className="hidden lg:block">
              <Map />
            </div>
          </div>
          <div className="text-left flex flex-row flex-wrap gap-10 w-4/5 justify-between m-auto">
            {locationDetails.map((el, i) => {
              return (
                <div key={i} className="flex flex-col gap-2 md:mx-auto">
                  <h4 className="text-xl text-[#E20613] font-medium">
                    {el.title}
                  </h4>
                  <Link
                    to={el.to}
                    target="_blank"
                    className={`flex flex-row gap-2 custom-link-hover ${
                      i === 0 ? "w-72" : "w-max"
                    }`}
                  >
                    <img src={el.icon} alt={el.alt} className={`w-6 h-6`} />
                    <h5 className="text-lg link-text text-white">{el.text}</h5>
                  </Link>
                  {i === 1 && (
                    <CopyToClipboard
                      textToCopy={13619380059}
                      icon={weChat_icon}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <h6 className="mt-10 pb-2 w-max m-auto">
          {t("done_by")}{" "}
          <a
            className="custom-link-hover"
            href="https://www.kreatech.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="link-text">Kreatech</span>
          </a>
        </h6>
      </div>
      <TopBottomNavbar inFooter />
    </>
  );
};

export default Footer;
