import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/english.json";
import translationCH from "./locales/chinese.json";
import translationJP from "./locales/japanese.json";
import translationKR from "./locales/korean.json";
import translationTH from "./locales/thai.json";
import translationBangla from "./locales/bangla.json";
import translationFrench from "./locales/french.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

i18next.init({
  interpolation: { escapeValue: false },
});
const resources = {
  english: {
    translation: translationEN,
  },
  chinese: {
    translation: translationCH,
  },
  japanese: {
    translation: translationJP,
  },
  korean: {
    translation: translationKR,
  },
  thai: {
    translation: translationTH,
  },
  bangla: {
    translation: translationBangla,
  },
  french: {
    translation: translationFrench,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "english",
  fallbackLng: "english",
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Router>
        <App />
        <ToastContainer />
      </Router>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
