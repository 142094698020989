import "./General.css";
export const arrowStyle =
  "px-[2px] pb-[1px] text-[#3581F0] border border-[#3581F0] hover:border-inherit hover:bg-gradient-to-tr from-[#2777E8] from-10% via-[#3581F0] via-30% to-[#6AD7E5] to-90% hover:text-white rounded-full m-auto";
export const gradBGBlue =
  "bg-gradient-to-tr from-[#2777E8] from-10% via-[#3581F0] via-20% to-[#6AD7E5] to-90%";
export const gradTextBlue =
  "text-transparent bg-clip-text bg-gradient-to-tr from-[#2777E8] from-10% via-[#3581F0] via-30% to-[#6AD7E5] to-90%";
export const buttonHover =
  "text-white z-30 px-3 py-2 rounded-md font-semibold cursor-pointer hover:scale-110 transition-transform duration-300";
export const generalInput =
  "input input-bordered input-md w-[250px] bg-[#040C0C] focus:border-[#E20613] text-white";
export const positiveButton =
  "rounded-md w-max py-1 px-3 text-lg text-white bg-green-500 hover:bg-green-600 cursor-pointer";
export const generalselect =
  "select select-bordered select-md w-64 bg-[#040C0C] focus:border-[#E20613]";

export const redButton =
  "m-auto rounded-md w-max py-1 px-3 text-lg text-white bg-[#E20613] hover:scale-110 transition-transform duration-300 cursor-pointer";

export const hoverScale = "hover:scale-110 transition-transform duration-300";

export const slideDown = {
  animation: "slideDown 1s ease-in-out forwards",
};

export const slideUp = {
  animation: "slideUp 1s ease-in-out forwards",
};

export const slideLeft = {
  animation: "slideLeft 1s ease-in-out forwards",
};

export const slideRight = {
  animation: "slideRight 1s ease-in-out forwards",
};

export const textTitle = "font-Playfair";
export const textText = "font-Lato";
