import React, { useEffect, useState } from "react";
import orientalLogo from "../img/logo.png";

const NavbarLogo = ({ onClick, link = false }) => {
  const [scrolledPastFirstScreen, setScrolledPastFirstScreen] = useState(false);
  const position = scrolledPastFirstScreen;

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > window.innerHeight;
      setScrolledPastFirstScreen(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const inner = (
    <div className="flex items-center cursor-pointer">
      <img
        alt="Logo"
        src={orientalLogo}
        onClick={onClick}
        className={`${
          position === false
            ? "h-28 w-28 md:w-40 md:h-40"
            : "h-20 w-20 md:w-32 md:h-32"
        } ease-in-out mt-10 md:mt-24 hover:scale-110 transition-transform duration-500`}
      />
    </div>
  );

  if (link) return <a href="/">{inner}</a>;
  return <div>{inner}</div>;
};

export default NavbarLogo;
