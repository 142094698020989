import React from "react";
import gallery1 from "../../img/seo_optimized/Oriental_Taste_Gallery_Hero.jpg";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
const GalleryFirst = () => {
  const { t } = useTranslation();

  const backgroundStyle = {
    backgroundImage: `url(${gallery1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image 1s ease-in-out",
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="h-[400px]" style={backgroundStyle}>
        <div className="h-[400px] flex flex-col gap-8 px-2 m-auto justify-center items-center text-white">
          <h1 className="text-3xl md:text-5xl font-Playfair font-bold md:leading-normal">
            {t("discover_us")}
          </h1>
        </div>
      </div>
    </motion.div>
  );
};

export default GalleryFirst;
