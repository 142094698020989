import React from "react";
import { Slide, Fade } from "react-reveal";
import home42 from "../../img/seo_optimized/lanzhou_beef_noodles.jpg";
import fried_tripe from "../../img/seo_optimized/fried_tripe.jpg";
import home4points1 from "../../img/home4points1.png";
import home4points2 from "../../img/home4points2.png";
import home4points3 from "../../img/home4points3.png";
import Oriental_Taste_Kitchen_Chefs from "../../img/gallery/Oriental_Taste_Kitchen_Chefs1.jpg";
import { useTranslation } from "react-i18next";
import MyLink from "../../componets/MyLink";
const HomeFourth = () => {
  const { t } = useTranslation();
  const points = [
    { src: home4points1, text: t("delivery_30_minutes") },
    { src: home4points2, text: t("best_offer_prices") },
    { src: home4points3, text: t("pick_up_services_available") },
  ];
  return (
    <div className="flex flex-col-reverse md:flex-row flex-wrap gap-5 items-center justify-center m-auto py-10 w-11/12">
      <div className="text-white text-left flex flex-col gap-5 justify-center px-4">
        <Slide left duration={1400}>
          <h2 className="text-4xl md:text-5xl font-Playfair">
            {t("home_fourth_title")}
          </h2>
        </Slide>

        {points.map((el, i) => {
          return (
            <Slide left duration={1200}>
              <div key={i} className="flex gap-3">
                <img src={el.src} alt={t("icon")} className="h-7 w-7 my-auto" />
                <p className="text-lg font-Lato">{el.text}</p>
              </div>
            </Slide>
          );
        })}
        <Slide left duration={1000}>
          <MyLink
            to="/Menu"
            text="order_now"
            textColor={"white"}
            bgColor={"tranparent"}
            borderRed
            textSize={"sm"}
          />
        </Slide>
      </div>

      <div className="flex flex-row flex-wrap gap-3">
        <Fade bottom duration={1000}>
          <img
            src={Oriental_Taste_Kitchen_Chefs}
            alt={t("oriental_taste_kitchen_chef2")}
            className="w-80 h-52 m-auto"
          />
        </Fade>

        <div className="flex md:flex-col flex-row gap-3 m-auto">
          <Fade top duration={1200}>
            <img
              src={home42}
              alt={t("lanzhou_beef_noodles")}
              className="w-40 md:w-44 mb-4 md:mb-0"
            />
          </Fade>
          <Fade bottom duration={1200}>
            <img
              src={fried_tripe}
              alt={t("fried_tripe")}
              className="w-40 md:w-44 mt-5 md:mt-0 md:ml-5"
            />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default HomeFourth;
