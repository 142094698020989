import React from "react";
import About2 from "../../img/seo_optimized/oriental_taste_warm_welcome.png";
import { useTranslation } from "react-i18next";
import { Fade, Slide, Zoom } from "react-reveal";
const AboutThird = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row gap-5 justify-center items-center m-auto py-10 w-11/12 lg:w-5/6">
      <div className="text-white text-left flex flex-col gap-5">
        <Slide left duration={1400}>
          <p className="font-Playfair text-4xl md:text-5xl">
            {t("about_third_title")}
          </p>
        </Slide>

        <Zoom duration={1200}>
          <img
            src={About2}
            alt={t("oriental_taste_warm_welcome")}
            className="m-auto w-11/12 lg:hidden"
          />
        </Zoom>
        <Slide left duration={1200}>
          <p className="font-Lato text-lg">{t("about_third_text")}</p>
        </Slide>
      </div>

      <Fade right duration={1200}>
        <img
          src={About2}
          alt={t("oriental_taste_warm_welcome")}
          className="hidden lg:block lg:w-2/3"
        />
      </Fade>
    </div>
  );
};

export default AboutThird;
