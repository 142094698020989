import React from "react";
import rating4andHalf from "../../img/rating4andHalf.png";
import rating5 from "../../img/rating5.png";
import home6reviewe1 from "../../img/seo_optimized/oriental_taste_restaurant_sitting.jpg";
import home6reviewe2 from "../../img/seo_optimized/Oriental_Taste_Restaurant_entrance_light.jpg";
import home6reviewe3 from "../../img/seo_optimized/Oriental_Taste_Restaurant.jpg";
import tripadvisor from "../../img/tripadvisor.png";
import facebookWithReviews from "../../img/facebookWithReviews.png";
import googleWithReviews from "../../img/googleWithReviews.png";
import { useTranslation } from "react-i18next";
import { Fade, Slide, Zoom } from "react-reveal";
const HomeSixth = () => {
  const { t } = useTranslation();
  const reviews = [
    {
      src: home6reviewe1,
      text: t("home_sixth_review1"),
      alt: t("oriental_taste_restaurant_sitting"),
    },
    {
      src: home6reviewe2,
      text: t("home_sixth_review2"),
      alt: t("Oriental_Taste_Restaurant_entrance_light"),
    },
    {
      src: home6reviewe3,
      text: t("home_sixth_review3"),
      alt: t("Oriental_Taste_Restaurant"),
    },
  ];
  const brands = [
    {
      src: tripadvisor,
      alt: "Tripadvisor",
      // to: "https://www.tripadvisor.com/Restaurant_Review-g293936-d26859417-Reviews-Oriental_Taste-Dhaka_City_Dhaka_Division.html"
    },
    {
      src: facebookWithReviews,
      alt: t("facebookWithReviews"),
    },
    {
      src: googleWithReviews,
      alt: t("googleWithReviews"),
    },
  ];
  return (
    <div className="bg-[#1D1D1B] flex flex-col gap-5 items-center justify-evenly m-auto pb-10 w-11/12">
      <Zoom duration={1000}>
        <h2 className="text-white text-4xl md:text-5xl font-Playfair md:w-1/2">
          {t("home_sixth_title")}
        </h2>
      </Zoom>
      <img src={rating4andHalf} alt={t("rating")} className="animate-pulse" />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 text-left">
        {reviews.map((el, i) => {
          return (
            <div
              key={i}
              className={`${
                i % 2 === 0
                  ? "flex-col-reverse lg:flex-col"
                  : "flex-col-reverse lg:flex-col-reverse"
              } flex gap-3 m-auto`}
            >
              <Slide top={i === 0 || i === 2} bottom={i === 1} duration={1000}>
                <div className="bg-white p-3">
                  <img src={rating5} alt={t("rating")} className="" />
                  <p className="md:text-base text-sm font-semibold font-Lato">
                    {el.text}
                  </p>
                </div>
              </Slide>
              <Fade
                // bottom={i === 0 || i === 2} top={i === 1}
                duration={1000}
              >
                <img
                  src={el.src}
                  alt={el.alt}
                  className={`w-max ${i % 2 === 0 ? "mb-auto" : "mt-auto"}`}
                />
              </Fade>
            </div>
          );
        })}
      </div>
      <Zoom duration={1000}>
        <div className="flex flex-row flex-wrap gap-5 justify-center items-center">
          {brands.map((el, i) => {
            return (
              <img
                key={i}
                src={el.src}
                alt={el.alt}
                className={` ${i === 0 ? "w-48 h-10" : "w-40 h-16"}`}
              />
            );
          })}
        </div>
      </Zoom>
    </div>
  );
};

export default HomeSixth;
