import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { hoverScale } from "../../componets/StyleStandardize";
import "../../componets/TopBottomNavbar.css";
import chefMain from "../../img/gallery/main_chef.jpg";
import Oriental_Taste_Dining_Menu from "../../img/gallery/Oriental_Taste_Dining_Menu.jpg";
import Oriental_Ambiance from "../../img/gallery/Oriental_Ambiance.jpg";
import Beef_Fried_Rice from "../../img/gallery/Beef_Fried_Rice.jpg";
import coldAppetizer from "../../img/menu/garlic_flavored_chicken.jpg";
import hotDishes from "../../img/menu/Braised_Lamb_Yellow_Sauce.jpg";
import premiumNoodleSelection from "../../img/menu/Braised_Lamb_Noodles.jpg";
import Beef_and_Onion_Dumplings from "../../img/gallery/Beef_and_Onion_Dumplings.jpg";
import interior2 from "../../img/gallery/Oriental_Ambiance_.jpg";
import oriental_taste_beef_menu from "../../img/gallery/oriental_taste_beef_menu.jpg";
import five_spice_chicken from "../../img/signature/five_spice_chicken.jpg";
import medicinal_pigeon_soup from "../../img/signature/medicinal_pigeon_soup.jpg";
import signature_peking_duck from "../../img/signature/signature_peking_duck.jpg";
import Oriental_Taste_VIP_Rooms from "../../img/gallery/Oriental_Taste_VIP_Rooms.jpg";
import interior1 from "../../img/gallery/Oriental_Taste_interior.jpg";
import Oriental_Taste_Kitchen_Chefs from "../../img/gallery/Oriental_Taste_Kitchen_Chefs1.jpg";
import Oriental_Taste_Kitchen_Chef2 from "../../img/gallery/Oriental_Taste_Kitchen_Chefs2.jpg";
import oriental_taste_kitchen_chef from "../../img/gallery/oriental_taste_kitchen_chef.jpg";
import Oriental_Taste_Dining_ambiance from "../../img/gallery/Oriental_Taste_Dining_ambiance.jpg";
import lemon_marinated_chicken_feet from "../../img/gallery/lemon_marinated_chicken_feet .png";
import oriental_food from "../../img/gallery/oriental_food.jpg";
import cornSoup from "../../img/menu/Chinese_hot_sour_soup.jpg";
import fresh_juice from "../../img/menu/fresh_juice.jpg";
import kiwiMilkshake from "../../img/menu/milkshake.jpg";

const GallerySecond = () => {
  const { t } = useTranslation();
  const [all, setAll] = useState(0); // 0 - all, 1 - interior, 2- food, 3- chefs
  const imageCol1 = [
   
    {
      src: Oriental_Taste_Kitchen_Chef2,
      alt: t("choriental_taste_kitchen_cheff2"),
      all: 3,
    },
    {
      src: Beef_and_Onion_Dumplings,
      alt: t("Beef_and_Onion_Dumplings"),
      all: 2,
    },
    {
      src: Oriental_Ambiance,
      alt: t("Oriental_Ambiance"),
      all: 1,
    },
    { src: Beef_Fried_Rice, alt: t("Beef_Fried_Rice"), all: 2 },
    { src: interior2, alt: t("interior2"), all: 1 },
    { src: coldAppetizer, alt: t("Garlic_flavored_Chicken"), all: 2 },
    { src: hotDishes, alt: t("Braised_Lamb_Yellow_Sauce"), all: 2 },
    { src: fresh_juice, alt: t("Fresh Juice"), all: 2 },
  ];

  const imageCol2 = [
    {
      src: chefMain,
      alt: t("chefMain"),
      all: 3,
    },
    {
      src: Oriental_Taste_VIP_Rooms,
      alt: t("Oriental_Taste_VIP_Rooms"),
      all: 1,
    },
    { src: five_spice_chicken, alt: t("five_spice_chicken"), all: 2 },
    {
      src: Oriental_Taste_Dining_ambiance,
      alt: t("Oriental_Taste_Dining_ambiance"),
      all: 1,
    },
    {
      src: oriental_food,
      alt: t("oriental_food"),
      all: 2,
    },
    {
      src: lemon_marinated_chicken_feet,
      alt: t("lemon_marinated_chicken_feet"),
      all: 2,
    },

    { src: medicinal_pigeon_soup, alt: t("medicinal_pigeon_soup"), all: 2 },
    { src: premiumNoodleSelection, alt: t("Braised_Lamb_Noodles"), all: 2 },
    { src: cornSoup, alt: t("Chinese_Hot_Sour_Soup"), all: 2 },
    
  ];

  const imageCol3 = [
    {
      src: oriental_taste_kitchen_chef,
      alt: t("Oriental_Taste_Kitchen_Chefs"),
      all: 3,
    },
    {
      src: signature_peking_duck,
      alt: t("peking_duck"),
      all: 2,
    },
    { src: interior1, alt: t("interior1"), all: 1 },
    {
      src: Oriental_Taste_Kitchen_Chefs,
      alt: t("oriental_taste_kitchen_chef2"),
      all: 3,
    },
    {
      src: oriental_taste_beef_menu,
      alt: t("oriental_taste_beef_menu"),
      all: 2,
    },
    {
      src: Oriental_Taste_Dining_Menu,
      alt: t("Oriental_Taste_Dining_Menu"),
      all: 2,
    },
    { src: kiwiMilkshake, alt: t("Kiwi Milkshake"), all: 2 },
    
  ];

  const imageType = [t("all"), t("interior"), t("food"), t("chefs")];
  return (
    <div className="py-10 flex flex-col gap-6">
      {/* Buttons */}
      <div className="flex gap-6 justify-center text-white text-xl font-Playfair font-bold">
        {imageType.map((el, i) => {
          return (
            <div className="custom-link-hover">
              <button
                key={i}
                onClick={() => {
                  setAll(i);
                }}
                className={`transition-opacity duration-500 link-text px-1 ${
                  all === i ? "opacity-100" : "opacity-50"
                } ${hoverScale} hover:text-[#E20613] hover:opacity-100`}
              >
                {el}
              </button>
            </div>
          );
        })}
      </div>

      {/* Images */}
      <div className="flex flex-row flex-wrap w-11/12 md:w-3/4 gap-6 justify-center mx-auto">
        <div className="flex flex-col gap-6 mb-auto">
          {imageCol1.map((el, i) => {
            return (
              <>
                {(all === 0 || el.all === all) && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <img
                      key={i}
                      src={el.src}
                      alt={el.alt}
                      className="w-72 m-auto"
                    />
                  </motion.div>
                )}
              </>
            );
          })}
        </div>

        <div className="flex flex-col gap-6 mb-auto">
          {imageCol3.map((el, i) => {
            return (
              <>
                {(all === 0 || el.all === all) && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <img
                      key={i}
                      src={el.src}
                      alt={el.alt}
                      className="w-72 m-auto"
                    />
                  </motion.div>
                )}
              </>
            );
          })}
        </div>

        <div className="flex flex-col gap-6 mb-auto">
          {imageCol2.map((el, i) => {
            return (
              <>
                {(all === 0 || el.all === all) && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <img
                      key={i}
                      src={el.src}
                      alt={el.alt}
                      className="w-72 m-auto"
                    />
                  </motion.div>
                )}
              </>
            );
          })}
        </div>
      </div>
      {/* images */}
    </div>
  );
};

export default GallerySecond;
