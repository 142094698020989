import React, { useState } from "react";
// import chefMain from "../../img/chefMain.jpg";
// import chef2 from "../../img/chef2.jpg";
import chefs from "../../img/seo_optimized/oriental_taste_kitchen_chef.jpg";
import { useTranslation } from "react-i18next";

const OurTeam = () => {
  const { t } = useTranslation();
  const [click1, setClick1] = useState(false);
  const [click2, setClick2] = useState(false);
  const chefDescription = [
    // {
    //   image: chefMain,
    //   text: t("our_team_1_text"),
    //   function: () => {
    //     setClick1(!click1);
    //     if (click2) setClick2(false); // Revert click2 state if it's true
    //   },
    //   speech: t("our_team_1_speech"),
    // },
    // {
    //   image: chef2,
    //   text: t("our_team_2_text"),
    //   function: () => {
    //     setClick2(!click2);
    //     if (click1) setClick1(false); // Revert click1 state if it's true
    //   },
    //   speech: t("our_team_2_speech"),
    // },
    {
      image: chefs,
      text: t("our_team_1_text"),
      function: () => {
        setClick1(!click1);
        if (click2) setClick2(false); // Revert click2 state if it's true
      },
      speech: t("our_team_1_speech"),
    },
  ];

  const handleChefClick = (index) => {
    if (index === 0) {
      setClick1(!click1);
      if (click2) setClick2(false); // Revert click2 state if it's true
    } else if (index === 1) {
      setClick2(!click2);
      if (click1) setClick1(false); // Revert click1 state if it's true
    }
  };

  return (
    <div className="text-white py-10">
      <p className="font-Playfair text-[42px] font-semibold text-center p-4">
        {t("our_team")}
      </p>
      <div className="flex md:flex-row flex-wrap items-center justify-center m-auto font-bold">
        {chefDescription.map((el, i) => {
          return (
            <div
              key={i}
              className={`flex justify-center px-4 h-[400px] w-[320px] md:w-[440px] hover:scale-100 transition-transform duration-300`}
            >
              {(i === 0 ? click1 && !click2 : click2 && !click1) ? (
                <div className="px-2 bg-[#E20613] flex flex-col gap-6 justify-center text-center h-[380px] opacity-100 transition-opacity duration-300 ease-in-out">
                  <p className="font-Lato text-lg">{el.speech}</p>
                  <p
                    className="text-[28px] text-4xl mx-auto cursor-pointer"
                    onClick={el.function}
                  >
                    -
                  </p>
                </div>
              ) : (
                <div>
                  <img
                    alt={t("chefs")}
                    className="object-cover shadow-md transition-transform transform brightness-80 hover:brightness-50"
                    src={el.image}
                  />
                  <div className="w-[320px] md:w-[450px] backdrop-blur-sm absolute flex flex-col gap-5 justify-end inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out">
                    <p className="text-black font-Lato text-lg text-center px-5">
                      {el.text}
                    </p>
                    <p
                      className="text-[#E20613] text-4xl mx-auto cursor-pointer"
                      onClick={() => handleChefClick(i)}
                    >
                      +
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurTeam;
