// FloatingButton.js
import React, { useEffect, useRef, useState } from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import weChatIcon from "../img/weChatIcon.png";
import whatsAppIcon from "../img/whatsAppIcon.jpg";
import ConnectIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import { Link } from "react-router-dom";
import { hoverScale } from "./StyleStandardize";
import { useTranslation } from "react-i18next";
const FloatingButton = ({ onClick }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [showWeChatText, setShowWeChatText] = useState(false);
  const weChatButtonRef = useRef(null);
  const handleExpand = () => {
    setExpanded(!expanded);
    setShowWeChatText(false);
  };

  const handleWeChatButtonClick = () => {
    setShowWeChatText(!showWeChatText);
  };
  const handleClickOutsideWeChat = (event) => {
    if (
      weChatButtonRef.current &&
      !weChatButtonRef.current.contains(event.target)
    ) {
      setShowWeChatText(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideWeChat);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideWeChat);
    };
  }, []);

  const fabStyle = {
    backgroundColor: "#E20613",
    transition: "transform 0.5s ease",
    transform: expanded
      ? "translateX(225px) translatey(0px) rotate(135deg)"
      : "translateX(225px) rotate(0)",
  };

  const mailButton = {
    transition: "transform 0.5s ease",
    transform: expanded
      ? "translateY(-65px) translateX(233px)"
      : "translateY(0)",
    opacity: expanded ? 1 : 0,
  };

  const whatsAppButton = {
    transition: "transform 0.5s ease",
    transform: expanded ? "translateX(178px)" : "translateY(0)",
    opacity: expanded ? 1 : 0,
  };

  const phonebutton = {
    transition: "transform 0.5s ease",
    transform: expanded
      ? "translateX(120px) translateY(-190px)"
      : "translateY(0)",
    opacity: expanded ? 1 : 0,
  };

  const weChatButton = {
    transition: "transform 0.5s ease",
    transform: expanded
      ? "translateX(66px) translateY(-130px)"
      : "translateY(0)",
    opacity: expanded ? 1 : 0,
  };

  const whatsappLink = `https://wa.me/+8801799250004`;

  return (
    <div className="" style={{ position: "relative" }}>
      {showWeChatText && (
        <>
          <div className="chat chat-end">
            <div className="chat-bubble absolute bottom-52 right-[70px]">
              WeChat: 13619380059
            </div>
          </div>
        </>
      )}

      <Fab
        style={fabStyle}
        aria-label="add"
        onClick={() => {
          handleExpand();
          onClick();
        }}
        className="rounded-full z-50"
      >
        {!expanded ? <ConnectIcon /> : <AddIcon />}
      </Fab>
      <Fab
        style={{ ...mailButton, backgroundColor: "#0063b0" }}
        aria-label="button1"
        onClick={() => console.log("Button 1 clicked!")}
        className="rounded-full z-50 absolute bottom-16 right-16"
      >
        <Link to="mailto:orientaltastebd@gmail.com" className={`${hoverScale}`}>
          <MailIcon style={{ color: "#ffffff" }} />
        </Link>
      </Fab>
      <Fab
        style={{ ...whatsAppButton, backgroundColor: "#25D366" }}
        aria-label="button2"
        onClick={() => console.log("Button 2 clicked!")}
        className="rounded-full z-50 absolute bottom-16 right-16"
      >
        <Link to={whatsappLink} target="_blank" rel="noopener noreferrer">
          <img
            src={whatsAppIcon}
            alt={t("whatsAppIcon")}
            className={`p-1 w-14 h-14 rounded-full ${hoverScale}`}
          />
        </Link>
      </Fab>
      <Fab
        style={{ ...phonebutton, backgroundColor: "#4CBB17" }}
        aria-label="button2"
        onClick={() => console.log("Button 3 clicked!")}
        className="rounded-full z-50 absolute bottom-16 right-16"
      >
        <Link
          to="tel:+8801799250004"
          className={`flex flex-row gap-2 items-center ${hoverScale}`}
        >
          <PhoneIcon style={{ color: "#ffffff" }} />
        </Link>
      </Fab>

      <Fab
        ref={weChatButtonRef}
        style={{ ...weChatButton, backgroundColor: "#ffffff" }}
        aria-label="button2"
        onClick={() => {
          handleWeChatButtonClick();
        }}
        className="rounded-full z-50 absolute bottom-16 right-16"
      >
        <img
          src={weChatIcon}
          alt={t("weChatIcon")}
          className={`w-14 h-14  rounded-full ${hoverScale}`}
        />
      </Fab>
    </div>
  );
};

export default FloatingButton;
