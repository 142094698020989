import React from "react";
import ContactFirst from "./sections/ContactFirst";
import ContactSecond from "./sections/ContactSecond";

const Contact = () => {
  return (
    <div className="bg-[#040C0C]">
      <ContactFirst />
      <ContactSecond />
    </div>
  );
};

export default Contact;
