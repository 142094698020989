import React from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CopyToClipboard = ({ textToCopy, icon }) => {
  const { t } = useTranslation();
  const copyTextToClipboard = (textToCopy) => {
    let text = textToCopy.textToCopy;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`${text} ${t("copied_clipboard")}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          style: {
            backgroundColor: "black",
          },
        });
      })
      .catch((error) => {
        toast.error(`${t("failed_to_copy_to_clipboard")}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
  return (
    <div
      className={`flex flex-row gap-2 custom-link-hover w-max cursor-pointer`}
      onClick={() => copyTextToClipboard({ textToCopy })}
    >
      {icon && <img src={icon} alt={t("icon")} className={`w-6 h-6`} />}

      <p className="text-lg link-text text-white">{textToCopy}</p>
    </div>
  );
};

export default CopyToClipboard;
