import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { hoverScale } from "./StyleStandardize";

const MyLink = ({
  textColor,
  textSize,
  text,
  to,
  bgColor,
  borderRed,
  borderBlack,
  borderWhite,
  big,
  font,
  border2,
}) => {
  const { t } = useTranslation();
  return (
    <Link
      to={to}
      className={`w-max text-${textColor} text-${textSize} rounded-full ${font} cursor-pointer bg-${bgColor} ${hoverScale} ${
        borderRed
          ? "border border-[#FF0000]"
          : borderBlack
          ? "border border-black"
          : borderWhite
          ? "border border-white"
          : ""
      } ${border2 ? "border-2" : ""} ${big ? "p-3 md:p-4" : "p-3"} font-Lato`}
    >
      {t(text)}
    </Link>
  );
};

export default MyLink;
