import React from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import MyLink from "../../componets/MyLink";
import home_culinary_maestro from "../../video/home_culinary_maestro_2.mp4";

const HomeThird = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row flex-wrap gap-5 items-center justify-center m-auto py-10 w-11/12">
      <video loop autoPlay muted className="w-96" src={home_culinary_maestro} />

      <div className="text-white text-left flex flex-col gap-5 justify-center px-4">
        <Fade bottom duration={1000}>
          <h2 className="text-4xl md:text-5xl font-Playfair">
            {t("home_third_title")}
          </h2>
          <h2 className="text-3xl md:text-4xl font-Playfair">烹饪大师</h2>
        </Fade>
        <Fade bottom duration={1200}>
          <p className="md:w-96 text-lg font-Lato">{t("home_third_text")}</p>
        </Fade>
        <Fade bottom duration={1400}>
          <MyLink
            to="/About"
            text="read_more"
            textColor={"white"}
            bgColor={"tranparent"}
            borderRed
            textSize={"sm"}
          />
        </Fade>
      </div>
    </div>
  );
};

export default HomeThird;
