import React from "react";
import AboutFirst from "./sections/AboutFirst";
import OurTeam from "./sections/OurTeam";
import AboutThird from "./sections/AboutThird";
import Helmet from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <link ref="canonical" href="https://www.orientaltastebd.com/About" />
      </Helmet>
      <div className="bg-[#040C0C]">
        <AboutFirst />
        <OurTeam />
        <AboutThird />
      </div>
    </>
  );
};

export default About;
