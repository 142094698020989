import React from "react";
import home41 from "../../img/seo_optimized/vip_room.jpg";
import home42 from "../../img/seo_optimized/lanzhou_beef_noodles.jpg";
import home43 from "../../img/seo_optimized/Oriental_Ambiance.jpg";
import { useTranslation } from "react-i18next";
import MyLink from "../../componets/MyLink";
import { Fade, Slide } from "react-reveal";
const HomeSeventh = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col-reverse md:flex-row flex-wrap gap-5 items-center justify-center m-auto py-20">
      <div className="text-white text-left flex flex-col gap-5 justify-center px-4">
        <Slide left duration={1400}>
          <h2 className="text-4xl md:text-5xl font-Playfair">
            {t("home_seventh_title")}
          </h2>
        </Slide>
        <Slide left duration={1300}>
          <h2 className="text-3xl md:text-4xl font-Playfair">会员福利</h2>
        </Slide>
        <Slide left duration={1200}>
          <p className="text-lg md:w-96 font-Lato">{t("home_seventh_text")}</p>
        </Slide>
        <Slide left duration={1000}>
          <MyLink
            to="/BecomeMember"
            text="become_member"
            textColor={"white"}
            bgColor={"tranparent"}
            borderRed
            textSize={"sm"}
          />
        </Slide>
      </div>

      <div className="flex flex-row flex-wrap gap-3">
        <Fade right duration={1000}>
          <img src={home41} alt={t("vip_rooms")} className="h-72 w-72 m-auto" />
        </Fade>
        <div className="flex md:flex-col flex-row items-center gap-5 m-auto">
          <Fade bottom duration={1000}>
            <img
              src={home42}
              alt={t("sauted_prawns_spicy_oil")}
              className="w-40 md:w-56 pb-4 md:p-0"
            />
          </Fade>
          <Fade top duration={1000}>
            <img
              src={home43}
              alt={t("Oriental_Ambiance")}
              className="w-44 md:w-80 pt-4 md:pl-4"
            />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default HomeSeventh;
