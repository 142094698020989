import React, { useEffect, useState } from 'react';
import axios from 'axios';

const OrdersByDate = () => {
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);
    const dateFull = '2023-12-02'; // needs to update it for input

    const apiUrl = 'https://api.kreatech.org';
    async function geInfo (dateFull) {
        try {
            const response = await axios.get(`${apiUrl}/dashboard/getAllOrders`, {
                params: { dateFull },
            });

            if (response.data.success) {
                console.log('response.data.data', response.data.data);
                setOrders(response.data.data);
                setError(null); // Clear any previous errors
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError('Error fetching orders');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await geInfo(dateFull);
        };

        fetchData();
    }, [dateFull]);

    return (
        <div className='mt-24'>
            <p>fhgxudruzui</p>
            {error && <p>Error: {error}</p>}
            {orders.length > 0 && (
                <ul>
                    {orders.map(order => (
                        <li key={order.orderTableID}>
                            {/* Display order information as needed */}
                            <p>Order ID: {order.orderID}</p>
                            {/* Add more fields as needed */}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default OrdersByDate;
